import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authCode: "",
    successMessage: "",
    routerDetails: {},
    errorMessage: "",
    id_profile: "",
    name_profile: "",
    profile_id: "",
    county_id: "",
    institute_id: "",
    regional_nucleus_health_id: "",
    state_id: "",
    finished_release_conference: "",
    restrict_release_conference: "",
    conference_id: "",
  },
  mutations: {},
  actions: {},
  modules: {},
});
