export default {
  version: "1.0.6",

  // LOCALHOST
  // apiURL: "http://localhost:8000/api",
  // captchaURL: "http://localhost:8000/captcha/api/default",

  // DESENV
  // apiURL: "http://desenv.scseb.saude.ba.gov.br/api",
  // captchaURL: "http://desenv.scseb.saude.ba.gov.br/captcha/api/default",

  //HOMLOGA
  // apiURL: "http://homologa.scseb.saude.ba.gov.br/api",
  // captchaURL: "http://homologa.scseb.saude.ba.gov.br/captcha/api/default",

  //PROD
  apiURL: "https://scseb.saude.ba.gov.br/api",
  captchaURL: "https://scseb.saude.ba.gov.br/captcha/api/default",
};
