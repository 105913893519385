import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMask from 'v-mask'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue-apexcharts'
import Vuetify from "vuetify";
import pt from 'vuetify/es5/locale/pt';

const vuetify = new Vuetify({
  lang: {
    locales: { ptbr: pt },
    current: "ptbr",
  },
});
Vue.use(VueApexCharts)
Vue.use(VueMask);
Vue.use(Vuetify)
Vue.config.productionTip = false
Vue.use(VueSweetalert2);

Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
