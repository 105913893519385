import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../axios";
import store from "../store/index";

Vue.use(VueRouter);

/** @type {import('vue-router').NavigationGuard }  */
async function verificationRouter(to, from, next) {
  to.meta.fromName = from.name;
  to.meta.fromPath = from.path;
  await axios.get(`/auth/me`).then((response) => {
    store.state.authCode = response.status;
  });

  if (store.state.authCode == 200) {
    next();
  } else {
    next("/");
  }
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/dashboard",
    beforeEnter: verificationRouter,
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("../components/dashboard/Dashboard-comp.vue"),
      },
    ],
  },
  {
    path: "/people",
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Lista de Participantes",
        component: () => import("../components/people/People-comp.vue"),
      },
    ],
  },

  {
    path: "/instituicao",
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Instituição",
        component: () => import("../components/institute/Table-comp.vue"),
      },
    ],
  },
  {
    path: "/orgao",
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Orgão/Entidade",
        component: () => import("../components/agency/Table-comp.vue"),
      },
    ],
  },
  {
    path: "/municipios",
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Município",
        component: () => import("../components/county/Table-comp.vue"),
      },
    ],
  },
  {
    path: "/regiao-saude",
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Região de Saúde",
        component: () => import("../components/HealthRegion/Table-comp.vue"),
      },
    ],
  },
  {
    path: "/macro-regiao",
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Macrorregião",
        component: () =>
          import("../components/regionalNucleusHealth/Table-comp.vue"),
      },
    ],
  },

  {
    path: "/dashboard/conference",
    beforeEnter: verificationRouter,
    component: () => import("../views/Conference.vue"),
    children: [
      {
        path: "",
        name: "Conferências",
        component: () => import("../components/main/Main-conference.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/edit",
    //beforeEnter: verificationRouter,
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "editar conferência",
        component: () => import("../components/main/Main-edit-conference.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/launch",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Lançamento de conferência",
        component: () =>
          import("../components/municipality/Municipality-main.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/general",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Lançamento geral de conferência",
        component: () => import("../components/municipality/Launch-comp.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/institution",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Lançamento geral de Instituição",
        component: () =>
          import("../components/municipality/Institution-comp.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/regional-nucleus",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Lançamento geral de Instituição",
        component: () =>
          import("../components/municipality/RegionalNucleus-comp.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/state",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Lançamento geral de Instituição",
        component: () =>
          import("../components/municipality/State-comp.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/report",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Relatórios",
        component: () => import("../components/report/report-comp.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/graph-report",
    //beforeEnter: verificationRouter,
    component: () => import("../views/ConferenceLaunch.vue"),
    children: [
      {
        path: "",
        name: "Relatórios",
        component: () => import("../components/report/reportGraph-comp.vue"),
      },
    ],
  },
  {
    path: "/dashboard/conference/new",
    //beforeEnter: verificationRouter,
    component: () => import("../views/FormConference.vue"),
    children: [
      {
        path: "",
        name: "Cadastro de Conferências",
        component: () => import("../components/form/formConference-comp"),
      },
    ],
  },
  {
    path: "/usuarios",
    name: 'Usuarios',
    beforeEnter: verificationRouter,
    component: () => import("../views/Usuarios.vue"),
    children: [
      {
        path: "",
        name: "Lista de Usuários",
        component: () => import("../components/users/Table-comp.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
